<template>
  <header>
    <Head>
      <!-- Google tag (gtag.js) -->
      <component :is="'script'" async src="https://www.googletagmanager.com/gtag/js?id=G-852X59QW4R"></component>
      <component :is="'script'">
        window.dataLayer = window.dataLayer || [];

        function gtag() {
          window.dataLayer.push(arguments);
        }

        (function initializeGTag() {
          const gaScript = document.createElement('script');
          gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-852X59QW4R';
          gaScript.async = true;
          gaScript.onload = () => {
            gtag('js', new Date());
            gtag('config', 'G-852X59QW4R');
          };
          document.head.appendChild(gaScript);
        })();

      </component>
      <meta charset="utf-8"/>
      <title>Citron Digital - Site vitrine / Application web</title>
      <meta name="description" content="Citron Digital - Site vitrine / Application web"/>
      <meta name="keywords" content="Entrepreneur du digital spécialisé dans la création, la reprise et le conseil de projet site vitrine (pensé pour le référencement), Application web ou SAAS. Un processus simple : conception, design, développement, livraison puis maintenance. Franck web, Franck, Franck Besson, digitalisation, citron, SEO"/>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
      <meta name="imageLink" content="https://citrondigital.fr/logo.png">
      <meta itemprop="image" content="https://citrondigital.fr/logo.png">
      <!-- Open Graph / Facebook -->
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://citrondigital.fr/"/>
      <meta property="og:title" content="Le Citron Gigital"/>
      <meta property="og:description" content="Site vitrine / Application web"/>
      <meta property="og:image" content="https://www.citrondigital.fr/logo.png"/>
      <meta property="og:image:secure_url" content="https://www.citrondigital.fr/logo.png" />
      <!-- Twitter -->
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:url" content="https://citrondigital.fr"/>
      <meta property="twitter:title" content="Citron Digital"/>
      <meta property="twitter:description" content="Site vitrine / Application web"/>
      <meta property="twitter:image" content="https://citrondigital.fr/logo.png"/>
    </Head>
  </header>
  <main>
    <router-view v-if="this.$route.name === 'Home'"/>
    <div v-else>
      <HeaderComponent/>
      <div class="global-container">
         <router-view/>
      </div>
      <footer-component/>
    </div>
  </main>
</template>

<script>
  import {Head} from "@vueuse/head";
  import HeaderComponent from "@/components/HeaderComponent.vue";
  import FooterComponent from "@/components/FooterComponent.vue";

  export default {
    components: {
      FooterComponent,
      HeaderComponent,
      // eslint-disable-next-line vue/no-reserved-component-names
      Head,
    },
    data: function () {
      return {
        isPhone: false,
        isTablet: false,
        isDesktop: false,
      }
    },
    methods: {
      setResolutionType() {
        const MAX_RES_PHONE = 700;
        const MAX_RES_TABLET = 1200;
        this.isPhone = window.outerWidth < MAX_RES_PHONE;
        this.isTablet = MAX_RES_PHONE <= window.outerWidth && window.outerWidth < MAX_RES_TABLET;
        this.isDesktop = MAX_RES_TABLET <= window.outerWidth;
      }

    },
    mounted() {
      this.setResolutionType();
      window.onresize = function () {
        this.setResolutionType();
      }.bind(this);
    }
  }

</script>

<style lang="stylus">
  @import '~/src/common/style/common.styl';
  @import '~/src/common/style/fonts.styl';

  .global-container
    display flex
    flex-direction column
    justify-content center
    align-items center
    min-height calc(100% - 150px - 9em)
    padding-left 10%
    padding-right 10%
</style>