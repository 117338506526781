import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContactView from "@/views/ContactView.vue";
import PortfolioView from "@/views/PortfolioView.vue";
import ArticlesView from "@/views/ArticlesView.vue";
import ShowcaseWebsiteView from "@/views/ShowcaseWebsiteView.vue";
import WebApplicationView from "@/views/WebApplicationView.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: PortfolioView
  },
  {
    path: '/site-web',
    name: 'ShowcaseWebsiteView',
    component: ShowcaseWebsiteView
  },
  {
    path: '/application-web',
    name: 'WebApplicationView',
    component: WebApplicationView
  },
  {
    path: '/articles',
    name: 'Articles',
    component: ArticlesView
  },
  {
    path: "/:catchAll(.*)",
    component: HomeView
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
      return {el: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
});

export default router
