<template>

  <div class="bloc-container">
    <div class="text-container">
      <h2>Citron Digital</h2>
      <h3><strong>Micro entrepreuneur</strong> depuis juillet 2020</h3>
      <div>
        <p>Micro-entrepreneur spécialisé dans la création d'applications web (SAAS) et aussi de sites web :</p>
        <ul>
          <li>Accompagnement et conseil client</li>
          <li>Conception, création, hébergement d'application et de site web (VueJs, Meteor, NodeJs, MongoDB, OVH)</li>
          <li>DevOps : GitLab (container registry, repository npm et runner), intégration continue</li>
          <li>Facturation / Gestion / Commercial / Administratif</li>
          <li>Marketing Digital</li>
          <li>Micro-service REST avec NodeJs (express), Docker, MongoDB, RabbitMQ (service MQTT)</li>
          <li>Vue3, CSS réalisé en mobile first</li>
        </ul>
      </div>
    </div>
    <div class="image-container">
      <a href="https://citrondigital.fr/"><img src="img/citrondigital_logo.png" alt="Citron Digital" data-holder-rendered="true"></a>
    </div>
  </div>

  <div class="separator"></div>

  <div class="bloc-container">
    <div class="text-container">
      <h2>Viveris - Toulouse</h2>
      <h3><strong>Ingénieur logiciel</strong> depuis mars 2021</h3>
      <div>
        <p>Ingénieur logiciel full stack dans le domaine de la télécommunication :</p>
        <ul>
          <li>Java - Vue - PHP - Python</li>
          <li>CI - Jenkins - OpenStack</li>
          <li>Oracle Linux - Apache</li>
          <li>Développement de nouvelles fonctionnalités et correction de bug</li>
          <li>Environnement agile</li>
          <li>Validation & Qualité (SquashTest)</li>
          <li>Test automatisé en TypeScript via l'API Puppeteer et intégration dans Jenkins</li>
          <li>Développement d'une image docker avec PJSUA2 pour réaliser et recevoir des appels SIP</li>
          <li>Développement d'une application (Vue3 - REST via Report Portal) qui affiche les résultats des tests auto</li>
        </ul>
      </div>
    </div>
    <div class="image-container">
      <a href="https://www.acelys.fr/"><img src="img/viveris.png" alt="Viveris" data-holder-rendered="true"></a>
    </div>
  </div>

  <div class="separator"></div>

  <div class="bloc-container">
    <div class="text-container">
      <h2>Capcir - Toulouse</h2>
      <h3><strong>Ingénieur logiciel</strong> d'octobre 2020 à mars 2021</h3>
      <div>
        <p>Ingénieur logiciels dans le domaine de la santé, spécialisé dans la réalisation et la maintenance d'infrastructure de test :</p>
        <ul>
          <li>Remise en place des tests unitaire et d’intégration</li>
          <li>Mise en place d’une intégration continue via GitLab Runner & Docker</li>
          <li>Correction de ticket en hotline</li>
          <li>Développement de nouvelle fonctionnalité</li>
        </ul>
      </div>
    </div>
    <div class="image-container">
      <a href="https://www.capcir.fr/"><img src="img/capcir.png" alt="Capcir" data-holder-rendered="true"></a>
    </div>
  </div>

  <div class="separator"></div>

  <div class="bloc-container">
    <div class="text-container">
      <h2>Acelys Service Numérique - Montpellier</h2>
      <h3><strong>Développeur full stack</strong> depuis janvier 2019</h3>
      <div>
        <p>Développement et maintenance d'un logiciel en JEE au sein d'un centre de service :</p>
        <ul>
          <li>Java EE (version 8) - Spring - Hibernate</li>
          <li>SQL - PL SQL (Oracle)</li>
          <li>Angular et Vaadin</li>
          <li>Travail en équipe de 15 personnes (SCRUM)</li>
          <li>Migration AngularJS vers Angular6</li>
          <li>WebService REST</li>
          <li>Test unitaire et d'intégration (JUnit)</li>
          <li>Test de non régression avec Selenium/Zalenium et Procator</li>
          <li>Maintenance et Développement (tickets Redmine/Jira)</li>
          <li>Correspondant Sécurité des Systèmes d'Information (CSSI)</li>
          <li>Intégration continue avec Jenkins</li>
          <li>Mapper Selma</li>
        </ul>
      </div>
    </div>
    <div class="image-container">
      <a href="https://www.acelys.fr/"><img src="img/acelys.png" alt="Acelys" data-holder-rendered="true"></a>
    </div>
  </div>

  <div class="separator"></div>

  <div class="bloc-container">
    <div class="text-container">
      <h2>Renault - Metz</h2>
      <h3><strong>Développeur full stack</strong> de septembre 2017 à décembre 2018</h3>
      <div>
        <p>Développement d'une ERP (Entreprise resource planning) au sein de l'un des ateliers de l'usine. Des compétences d'<text style="text-decoration: underline;">analyse de processus métier</text> sont fortement sollicitées au cours de cette alternance et les languages utilisés sont : </p>
        <ul>
          <li>HTML</li>
          <li>CSS</li>
          <li>PHP</li>
          <li>JS</li>
          <li>MySQL</li>
        </ul>
      </div>
    </div>
    <div class="image-container">
      <a href="https://www.renaultgroup.com/"><img src="img/groupe-renault.png" alt="Groupe Renault" data-holder-rendered="true"></a>
    </div>
  </div>

  <div class="separator"></div>

  <div class="bloc-container">
    <div class="text-container">
      <h2>U express les Marines - St. Hilaire de Riez</h2>
      <h3><strong>Mise en rayon</strong> de 2014 à 2017 durant les saisons</h3>
      <p>
        <ul>
          <li>Horaire de nuit et week-end</li>
          <li>Gestion de stock</li>
          <li>Facing</li>
        </ul>
      </p>
    </div>
    <div class="image-container">
      <a href="https://www.magasins-u.com/"><img src="img/u.png" alt="U" data-holder-rendered="true"></a>
    </div>
  </div>

  <div class="separator"></div>

  <div class="bloc-container">
    <div class="text-container">
      <h2>Laboratoire ARAGO - Banyuls-sur-mer</h2>
      <h3><strong>Développeur d'objets connectés</strong> en 2017</h3>
      <div>
        <p>Développement de plusieurs projets d'objets connectés, dont notamment une forêt connectée et un aquarium connecté. Plusieurs technologies et languages ont été utilisés :</p>
        <ul>
          <li>Raspberry</li>
          <li>Arduino</li>
          <li>LoPy (Pycom)</li>
          <li>Python et Micro Python</li>
          <li>C</li>
          <li>HTML</li>
          <li>CSS</li>
          <li>PHP</li>
          <li>JS</li>
        </ul>
      </div>
    </div>
    <div class="image-container">
      <a href="https://www.cnrs.fr/fr"><img src="img/cnrs.png" alt="CNRS" data-holder-rendered="true"></a>
    </div>
  </div>

  <div class="separator"></div>

  <div class="bloc-container">
    <div class="text-container">
      <h2>E-WI Télécom - Saint Jean d'Angely</h2>
      <h3><strong>Développeur mobile</strong> en 2016</h3>
      <div>
        <p>Développement d'une application mobile sous IOS.</p>
        <ul>
          <li>Méthodologie Kanban</li>
          <li>Swift</li>
          <li>UX Design / Ergonomie</li>
        </ul>
      </div>
    </div>
    <div class="image-container">
      <a href="https://www.linkedin.com/company/e-wi-t%C3%A9l%C3%A9com/about/"><img class="rounded" src="img/e-wi.jpg" alt="E-wi Telecom" data-holder-rendered="true"></a>
    </div>
  </div>

  <div class="separator"></div>

  <div class="bloc-container">
    <div class="text-container">
      <h2>IKCOM - Rivesaltes</h2>
      <h3><strong>Développeur full stack</strong> entre 2016 et 2017</h3>
      <div>
        <p>Développement d'une application mobile sous IOS et Android en utilisant le framework Symfony (utilisation d'une web-view). Les technologies et languages suivants ont été utilisés :</p>
        <ul>
          <li>Symfony</li>
          <li>HTML</li>
          <li>CSS</li>
          <li>PHP</li>
          <li>JS</li>
          <li>Java</li>
          <li>Objective-C</li>
          <li>Script shell et tâches chroniques</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="separator"></div>

  <div class="bloc-container">
    <div class="text-container">
      <h2>IUT - La Rochelle</h2>
      <h3><strong>Étudiant</strong> de 2014 à 2016</h3>
      <div>
        <p>Au cours de 2 ans de formation à l'institut universitaire et technologique de La Rochelle j'ai réalisé différents projets :</p>
        <ul>
          <li>Conception et Programmation d'un logiciel en groupe (java)</li>
          <li>Programmation d'un jeu en console (C++)</li>
          <li>Conception et programmation d'un site web en groupe</li>
          <li>Conception et programmation d'un microcontrôleur en groupe (c, assembleur, java pour l'IHM) pour l'entreprise Tecdron</li>
        </ul>
      </div>
    </div>
    <div class="image-container">
      <a href="https://www.iut-larochelle.fr/"><img src="img/iut_la_rochelle.png" alt="IUT La Rochelle" data-holder-rendered="true"></a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ExperiencesComponent',
}
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';

.blocs-container
  display flex
  flex-direction column

  .bloc-container
    display flex
    flex-direction column
  .image-container
    display flex
    align-items: center;
    justify-content: center;
    height 10rem
    img
      max-height 10rem
      max-width 10rem
      &.rounded
        border-radius 15px

  .separator
    margin-top 30px
    margin-bottom 15px
    height 1px
    background-color FONT_COLOR
    opacity 0.3
    width 35%

  .header-separator
    margin-bottom 15px
    height 1px
    background-color FONT_COLOR
    opacity 0.3
    width 100%

strong
  color THEME_COLOR_SECONDARY

.header-tab
  display flex
  justify-content space-around
  a
    text-decoration none
    *
      color FONT_COLOR_CLICKABLE

@media only screen and (min-width: DESKTOP_SIZE)
  .bloc-container
    flex-direction row !important
    justify-content space-between
    .text-container
      width 100%
    .image-container
      height inherit
      width 30rem
      img
        max-height 13rem
        max-width 13rem
</style>