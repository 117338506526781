<template>
  <div class="global-container">
    <div class="header-container">
      <div class="header-left-container">
        <h1>L'agence web qui a du peps</h1>
      </div>
      <div class="header-right-container">
        <h1><strong>CITRON</strong> DIGITAL</h1>
        <span style="height: 2px; background-color: rgba(232,255,255,0.25); display: block"></span>
        <p>
          Depuis <strong>2020</strong>, j'accompagne mes clients dans la <strong>création</strong> ou la
          <strong>reprise</strong> de leurs projets. Je suis spécialisé dans le web et le développement d'applications de type SAAS.
        </p>
        <p><router-link :to="'/portfolio'" class="hyperlink">En apprendre plus sur moi</router-link></p>
      </div>
    </div>
    <div class="center-container">
      <img src="img/citrondigital_background.webp" alt="Citron Digital"/>
      <div class="heartbeat h1"></div>
      <div class="heartbeat h2"></div>
      <div class="heartbeat h3"></div>
      <div class="heartbeat h4"></div>
      <div class="heartbeat h5"></div>
      <div class="heartbeat h6"></div>
      <div class="heartbeat h7"></div>
      <div class="heartbeat h8"></div>
      <div class="heartbeat h9"></div>
      <div class="heartbeat h10"></div>
    </div>
    <div class="footer-container">
      <div>
        <h2>SITE WEB</h2>
        <ul>
          <li>Vitrine</li>
          <li>E-commerce</li>
          <li>Marketplaces</li>
          <li><router-link :to="'/site-web'" class="hyperlink">En savoir plus</router-link></li>
        </ul>
      </div>
      <div>
        <h2>APPLICATION WEB</h2>
        <ul>
          <li>SaaS, <router-link :to="'/application-web'" class="hyperlink">des exemples</router-link></li>
          <li>Accompagnement</li>
          <li>Maintenance</li>
        </ul>
      </div>
      <div>
        <h2>PRESTATION DE SERVICE</h2>
        <ul>
          <li>Conseils</li>
          <li>Développement</li>
          <li>Digitalisation</li>
          <li>SOE, Référencement naturel</li>
          <li><router-link :to="'/contact'" class="hyperlink">Contactez-moi</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'HomeView',
    }
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';

// ------------------------------------------------------
//                         GLOBAL
// ------------------------------------------------------
.global-container
  min-height 100%
  display flex
  flex-direction column
  justify-content space-between

@media only screen and (min-width: DESKTOP_SIZE)
  .global-container
    padding 0

main
  padding-left 5%
  padding-right 5%
// ------------------------------------------------------
//                         HEADER
// ------------------------------------------------------
.header-container
  width 100%
  display flex
  flex-direction column
  align-items flex-start
  div
    padding-top 30px

//---------------------- RESPONSIVE ----------------------
@media only screen and (min-width: DESKTOP_SIZE)
  .header-container
    display flex
    justify-content space-around
    flex-direction row
    z-index 1

  .header-left-container
    width 800px

  .header-right-container
    width 400px

// ------------------------------------------------------
//                         FOOTER
// ------------------------------------------------------
.footer-container
  width 100%
  display flex
  flex-direction column
  align-items center
  div
    min-width 300px
    padding-bottom 30px
    padding-left 15%
    h3
      display block
    li
        display block


//---------------------- RESPONSIVE ----------------------
@media only screen and (min-width: DESKTOP_SIZE)
  .footer-container
    display flex
    justify-content center
    flex-direction row
    gap 6%
    padding-bottom 2em
    align-items flex-start
    div
      padding-left 0

// ------------------------------------------------------
//                         CENTER
// ------------------------------------------------------

.center-container
  width 100%
  height 300px
  display flex
  align-items center
  justify-content center
  max-width 100%
  max-height 30em
  margin-bottom 3em
  z-index 0
  img
    z-index 2
    max-height 30em
    max-width 100%
    width auto

.heartbeat
  margin-left 2em
  margin-top 4.5em
  aspect-ratio 1/1
  position absolute
  z-index 1
  background-color rgba(84, 87, 101, 0.1)
  border-radius 50%
  animation pulse 3s infinite
  &.h1
    width 50%
  &.h2
    width 48%
  &.h3
    width 46%
  &.h4
    width 44%
  &.h5
    width 42%
  &.h6
    width 40%
  &.h7
    width 38%
  &.h8
    width 36%
  &.h9
    width 34%
  &.h10
    width 32%

@keyframes pulse
  0%
    transform scale(1)
  50%
    transform scale(1.1)
  100%
    transform scale(1)

//---------------------- RESPONSIVE ----------------------
@media only screen and (min-width: DESKTOP_SIZE)
  .center-container
    margin-top -200px
    margin-bottom 0
    img
      height 17em
      max-height 17em
      padding-bottom 5em

  .heartbeat
    margin-left 1.8em
    margin-top -1em
    background-color rgba(84, 87, 101, 0.05)

    width auto !important
  &.h1
    height 16em
  &.h2
    height 15.6em
  &.h3
    height 15.2em
  &.h4
    height 14.8em
  &.h5
    height 14.4em
  &.h6
    height 14em
  &.h7
    height 13.6em
  &.h8
    height 13.2em
  &.h9
    height 13.8em
  &.h10
    height 13.4em
  
// ------------------------------------------------------
//                         DIVERS
// ------------------------------------------------------

h2
  color THEME_COLOR_SECONDARY

strong
  color THEME_COLOR_SECONDARY

p, li, label
  font-size 1.3em

</style>