<template>
  <div class="page-container">
    <h1>Contactez-moi !</h1>
    <p>
      Vous avez une question ? un projet ? Ou tout autre chose, n'hésitez pas ! Voici comment me contacter :
    </p>
    <div>
      <img src="img/linkedin.png" alt="Citron Digital"/>
      <label><a class="hyperlink" href="https://www.linkedin.com/in/franck-besson-fr/">Mon profil</a></label>
    </div>
    <div>
      <img src="img/mail.png" alt="Citron Digital"/>
      <label><a class="hyperlink" href="mailto:contact@citrondigital.fr">contact@citrondigital.fr</a></label>
    </div>
    <div>
      <img src="img/phone.png" alt="Citron Digital"/>
      <label><a class="hyperlink" href="tel:0614851819">06 14 85 18 19</a></label>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'ContactView'
    }
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';
.page-container
  min-height 100%
  display flex
  flex-direction column
  justify-content center
  align-items center
  p
    max-width 380px
  div
    padding-top 20px
    width 300px
    display flex
    align-items center
    label
      font-size 1.1em
      padding-left 20px
    img
      width 60px

.back-container
  display flex
  justify-content: center;
  .back
    height 4.5em
    fill FONT_COLOR

</style>