<template>
  <div>
    <h1>Mon portfolio</h1>

    <div class="blocs-container">

      <div class="header-tab">
        <a id="experiences" :class="{'selected' : this.tab === 'experiences'}" @click="this.tab = 'experiences'"><span>Expériences</span></a>
        <a id="formation" :class="{'selected' : this.tab === 'formation'}" @click="this.tab = 'formation'"><span>Formations</span></a>
      </div>

      <div class="header-separator"></div>

      <TrainingComponent v-if="this.tab === 'formation'"/>
      <ExperiencesComponent v-else/>

    </div>
  </div>
</template>

<script>
    import ExperiencesComponent from "@/components/ExperiencesComponent.vue";
    import TrainingComponent from "@/components/TrainingComponent.vue";

    export default {
        name: 'PortfolioView',
        components: {TrainingComponent, ExperiencesComponent},
        data: function () {
          return {
            tab: 'experiences'
          }
        },
    }
</script>

<style lang="stylus" scoped>
@import '~/src/common/style/constants.styl';
.back-container
  display flex
  justify-content center
  align-items center
  padding-top 20px
  width 300px
  img
    width 60px
  .back
    height 4.5em
    fill FONT_COLOR

.header-separator
  margin-bottom 15px
  height 1px
  background-color FONT_COLOR
  opacity 0.3
  width 100%

.separator
  margin-top 30px
  margin-bottom 15px
  height 1px
  background-color FONT_COLOR
  opacity 0.3
  width 35%

strong
  color THEME_COLOR_SECONDARY

.header-tab
  display flex
  justify-content space-around
  a
    display flex
    align-items center
    cursor pointer
    span
      font-size 1.4em
    text-decoration none
    *
      color FONT_COLOR_CLICKABLE
  .selected
    background-color #424c67
    border-top-left-radius 15px
    border-top-right-radius 15px
    padding 10px
    span
      font-size 1.7em
      color FONT_COLOR
</style>